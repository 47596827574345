<template>
  <div>
    <div class="task-nav">
      <div class="table-page-search-wrapper">
        <a-form layout="inline" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="学生">
                <a-input
                  placeholder="姓名或学号"
                  v-model="searchName"
                  allow-clear
                  @pressEnter="initSearchData('search')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="申请人">
                <a-select
                  show-search
                  allowClear
                  @search="searchHeadmaster"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  v-model="submitterId"
                >
                  <a-select-option v-for="item in headmasterList" :key="item.uuid" :value="item.uuid">{{
                    item.fullName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="审批状态">
                <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="approveStatus">
                  <a-select-option :value="item.value" v-for="(item, index) in approveStatusArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="变更类型">
                <a-select placeholder="请选择" allowClear @change="changeCategory" v-model="transactionCategory">
                  <a-select-option :value="item.value" v-for="(item, index) in transactionCategoryArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="变更原因">
                <a-select
                  placeholder="请选择"
                  allowClear
                  @change="initSearchData('search')"
                  v-model="transactionReason"
                >
                  <template v-if="transactionCategory === 'TRANSFER'">
                    <a-select-option :value="index" v-for="(item, index) in reasonArr['TRANSFER']" :key="index">{{
                      item
                    }}</a-select-option>
                  </template>
                  <template v-if="transactionCategory === 'RECOVER'">
                    <a-select-option :value="index" v-for="(item, index) in reasonArr['RECOVER']" :key="index">{{
                      item
                    }}</a-select-option>
                  </template>
                  <template v-if="transactionCategory === 'COMPENSATE'">
                    <a-select-option :value="index" v-for="(item, index) in reasonArr['COMPENSATE']" :key="index">{{
                      item
                    }}</a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="float: right; text-align: right">
              <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
                <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>

    <div class="table-wrap">
      <div class="sel-navwrap">
        <div class="sel-left">
          <a-select
            v-model="selType"
            class="sel-task"
            :default-value="selTabData[0].value"
            style="width: 110px; font-weight: bold"
            @change="initSearchData('search')"
          >
            <a-select-option v-for="item in selTabData" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="studentName" slot-scope="studentName, record">
          <span>{{ record.processVariables.student.name }} ({{ record.processVariables.student.code }})</span>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ getDdlLabel(subjectArr, subject) }}</span>
        </template>
        <template slot="finish" slot-scope="finish, record">
          <span>{{ initOptionsType(record.businessTaskStatus, approveStatusArr) }}</span>
        </template>

        <!-- <template slot="approverName" slot-scope="approverName, record">
          <span v-if="record.approverFirstName"
            >{{ `${record.approverFirstName ? record.approverFirstName : ''}` }}
            <span v-if="approverName">{{ `,${approverName}` }}</span>
          </span>
          <span v-else-if="approverName">{{ approverName }}</span>
          <span v-else>-</span>
        </template> -->
        <template slot="transactionCategory" slot-scope="transactionCategory">
          <span>{{ initOptionsType(transactionCategory, transactionCategoryArr) }}</span>
        </template>
        <template slot="approveStatus" slot-scope="approveStatus">
          <span :style="{ color: approveStatus === 'REJECTED' ? 'red' : '' }">{{
            initOptionsType(approveStatus, approveStatusArr)
          }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">查看详情</span>
        </template>
      </a-table>
    </div>

    <a-drawer
      class="test-main"
      title="审批详情"
      :body-style="{ paddingBottom: '80px' }"
      placement="right"
      width="700"
      :visible="visible"
      @close="onClose"
    >
      <div class="task-info" v-if="formInfoData">
        <div class="task-form">
          <p class="form-name" style="margin-bottom: 12px">
            {{ initOptionsType(formInfoData.detail.transactionCategory, transactionCategoryArr) }}

            <span class="pending-status" v-if="originFormInfoData.businessTaskStatus === 'PENDING'">待审核</span>
            <span class="success-status" v-if="originFormInfoData.businessTaskStatus === 'PASSED'">已通过</span>
            <span class="refuse-status" v-if="originFormInfoData.businessTaskStatus === 'REJECTED'">已拒绝</span>
            <span class="cancel-status" v-if="originFormInfoData.businessTaskStatus === 'CANCEL'">已撤销</span>
          </p>
          <div class="form-teacher">
            <img :src="defaultImg" alt="" />
            <span>申请人：{{ formInfoData.startUserName }}</span>
            |
            <span>申请时间：{{ formInfoData.createTime }}</span>
          </div>
        </div>
        <div class="line-wrap">
          <InfoTitle title="关联学生" />
          <div class="student-wrap">
            <div class="student-item">
              <img :src="defaultImg" alt="" class="handler-img" />
              <div>
                <p class="student-name cursor" @click="hrefStudent">
                  {{ formInfoData.student.name }} ({{ formInfoData.student.code }})>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="apply-detail">
          <InfoTitle title="申请详情" />
          <div class="apply-detailContent">
            <div class="apply-item">
              <span class="apply-label">变更类型：</span
              ><span class="apply-content">{{
                initOptionsType(formInfoData.detail.transactionCategory, transactionCategoryArr)
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">变更原因：</span
              ><span class="apply-content">{{ getFeedbackReason(formInfoData.detail) }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label"
                >{{ formInfoData.detail.transactionCategory === 'TRANSFER' ? '转出学员' : '变更学员' }}：</span
              ><span class="apply-content">{{ `${formInfoData.studentName}(${formInfoData.studentCode})` }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label"
                >{{ formInfoData.detail.transactionCategory === 'TRANSFER' ? '转出科目' : '变更科目' }}：</span
              ><span class="apply-content">{{ getDdlLabel(subjectArr, formInfoData.detail.subject) }}</span>
            </div>
            <!--课时转移-->
            <div class="apply-item" v-if="formInfoData.detail.transactionCategory === 'TRANSFER'">
              <span class="apply-label">转入学员：</span
              ><span class="apply-content">{{
                `${formInfoData.detail.toStudentName}(${formInfoData.detail.toStudentCode})`
              }}</span>
            </div>
            <div class="apply-item" v-if="formInfoData.detail.transactionCategory === 'TRANSFER'">
              <span class="apply-label">转入科目：</span
              ><span class="apply-content">{{ getDdlLabel(subjectArr, formInfoData.detail.toSubject) }}</span>
            </div>

            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['PURCHASED'] &&
                formInfoData.detail.cuTransferMap['PURCHASED'].cu
              "
            >
              <span class="apply-label">购买课时：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['PURCHASED'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_PURCHASED'] &&
                formInfoData.detail.cuTransferMap['GIFT_PURCHASED'].cu
              "
            >
              <span class="apply-label">购买赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_PURCHASED'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_DISTRIBUTION'] &&
                formInfoData.detail.cuTransferMap['GIFT_DISTRIBUTION'].cu
              "
            >
              <span class="apply-label">分销赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_DISTRIBUTION'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_RECOMMEND'] &&
                formInfoData.detail.cuTransferMap['GIFT_RECOMMEND'].cu
              "
            >
              <span class="apply-label">推荐赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_RECOMMEND'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_ADJUST'] &&
                formInfoData.detail.cuTransferMap['GIFT_ADJUST'].cu
              "
            >
              <span class="apply-label">手动变更课时：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_ADJUST'].cu }}</span>
            </div>

            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_HOMEWORK_COMPLETION'] &&
                formInfoData.detail.cuTransferMap['GIFT_HOMEWORK_COMPLETION'].cu
              "
            >
              <span class="apply-label">伴学奖赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_HOMEWORK_COMPLETION'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_SOCIAL_NETWORK_SHARE'] &&
                formInfoData.detail.cuTransferMap['GIFT_SOCIAL_NETWORK_SHARE'].cu
              "
            >
              <span class="apply-label">社交平台分享赠课：</span
              ><span class="apply-content">{{
                formInfoData.detail.cuTransferMap['GIFT_SOCIAL_NETWORK_SHARE'].cu
              }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_SIGNUP_SHARE'] &&
                formInfoData.detail.cuTransferMap['GIFT_SIGNUP_SHARE'].cu
              "
            >
              <span class="apply-label">入学分享赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_SIGNUP_SHARE'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_SMALL_LESSON'] &&
                formInfoData.detail.cuTransferMap['GIFT_SMALL_LESSON'].cu
              "
            >
              <span class="apply-label">小班课专属赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_SMALL_LESSON'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_COMPENSATE'] &&
                formInfoData.detail.cuTransferMap['GIFT_COMPENSATE'].cu
              "
            >
              <span class="apply-label">其他赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.cuTransferMap['GIFT_COMPENSATE'].cu }}</span>
            </div>

            <div
              class="apply-item"
              v-if="
                formInfoData.detail.transactionCategory === 'TRANSFER' &&
                formInfoData.detail.cuTransferMap &&
                formInfoData.detail.cuTransferMap['GIFT_SOCIAL_NETWORK_SHARE'] &&
                formInfoData.detail.cuTransferMap['GIFT_SOCIAL_NETWORK_SHARE'].quota
              "
            >
              <span class="apply-label">分享赠课额度：</span
              ><span class="apply-content">{{
                formInfoData.detail.cuTransferMap['GIFT_SOCIAL_NETWORK_SHARE'].quota
              }}</span>
            </div>
            <!--课时恢复，课时补偿-->
            <div class="apply-item" v-if="['RECOVER'].includes(formInfoData.detail.transactionCategory)">
              <span class="apply-label">相关课节：</span
              ><span
                class="apply-content"
                style="color: #3372fe; cursor: pointer"
                @click="playback"
                v-if="courseScheduleObj !== null"
                >{{
                  `${courseScheduleObj.startChinaDateTime}（${
                    courseScheduleObj.courseSectionRespDTO && courseScheduleObj.courseSectionRespDTO.sectionName
                  } ${courseScheduleObj.teacher && courseScheduleObj.teacher.fullName}）`
                }}</span
              >
            </div>
            <div class="apply-item" v-if="['RECOVER', 'COMPENSATE'].includes(formInfoData.detail.transactionCategory)">
              <span class="apply-label">课时数量：</span
              ><span class="apply-content">{{ formInfoData.detail.transactionAmount }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">飞书审批编号：</span
              ><span class="apply-content">{{ formInfoData.detail.feiShuNo }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">上传图片：</span>
              <div class="image-content">
                <el-image
                  v-for="src in formInfoData.detail.imageUrl"
                  :key="src"
                  :src="src"
                  fit="cover"
                  :preview-src-list="formInfoData.detail.imageUrl"
                />
              </div>
            </div>
            <div class="apply-item">
              <span class="apply-label">详细说明：</span
              ><span class="apply-content">{{ formInfoData.detail.transactionRemark }}</span>
            </div>
          </div>
        </div>
        <div
          class="apply-detail"
          v-if="formInfoData.detail.transactionCategory === 'TRANSFER' && formInfoData.detail.actualTransfer"
        >
          <InfoTitle title="实际转移" />
          <div class="apply-detailContent">
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['PURCHASED'] && formInfoData.detail.actualTransfer['PURCHASED'].cu
              "
            >
              <span class="apply-label">转移购买课时：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['PURCHASED'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_PURCHASED'] &&
                formInfoData.detail.actualTransfer['GIFT_PURCHASED'].cu
              "
            >
              <span class="apply-label">转移购买赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_PURCHASED'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_DISTRIBUTION'] &&
                formInfoData.detail.actualTransfer['GIFT_DISTRIBUTION'].cu
              "
            >
              <span class="apply-label">转移分销赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_DISTRIBUTION'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_RECOMMEND'] &&
                formInfoData.detail.actualTransfer['GIFT_RECOMMEND'].cu
              "
            >
              <span class="apply-label">转移推荐赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_RECOMMEND'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_ADJUST'] &&
                formInfoData.detail.actualTransfer['GIFT_ADJUST'].cu
              "
            >
              <span class="apply-label">转移手动变更课时：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_ADJUST'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_HOMEWORK_COMPLETION'] &&
                formInfoData.detail.actualTransfer['GIFT_HOMEWORK_COMPLETION'].cu
              "
            >
              <span class="apply-label">转移伴学奖赠课：</span
              ><span class="apply-content">{{
                formInfoData.detail.actualTransfer['GIFT_HOMEWORK_COMPLETION'].cu
              }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_SOCIAL_NETWORK_SHARE'] &&
                formInfoData.detail.actualTransfer['GIFT_SOCIAL_NETWORK_SHARE'].cu
              "
            >
              <span class="apply-label">转移社交平台分享赠课：</span
              ><span class="apply-content">{{
                formInfoData.detail.actualTransfer['GIFT_SOCIAL_NETWORK_SHARE'].cu
              }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_SIGNUP_SHARE'] &&
                formInfoData.detail.actualTransfer['GIFT_SIGNUP_SHARE'].cu
              "
            >
              <span class="apply-label">转移入学分享赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_SIGNUP_SHARE'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_SMALL_LESSON'] &&
                formInfoData.detail.actualTransfer['GIFT_SMALL_LESSON'].cu
              "
            >
              <span class="apply-label">转移小班课专属赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_SMALL_LESSON'].cu }}</span>
            </div>
            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_COMPENSATE'] &&
                formInfoData.detail.actualTransfer['GIFT_COMPENSATE'].cu
              "
            >
              <span class="apply-label">转移其他赠课：</span
              ><span class="apply-content">{{ formInfoData.detail.actualTransfer['GIFT_COMPENSATE'].cu }}</span>
            </div>

            <div
              class="apply-item"
              v-if="
                formInfoData.detail.actualTransfer['GIFT_SOCIAL_NETWORK_SHARE'] &&
                formInfoData.detail.actualTransfer['GIFT_SOCIAL_NETWORK_SHARE'].quota
              "
            >
              <span class="apply-label">转移分享赠课额度：</span
              ><span class="apply-content">{{
                formInfoData.detail.actualTransfer['GIFT_SOCIAL_NETWORK_SHARE'].quota
              }}</span>
            </div>
          </div>
        </div>
        <div class="apply-detail">
          <InfoTitle title="审批结果" />
          <div class="apply-detailContent" v-if="assigneesList.length > 0">
            <template>
              <MyProcessingRecords :class="subDetailType" :assigneesDatas="assigneesList" />
            </template>
          </div>
        </div>

        <div class="taskFormBtn" v-if="selType === 'PENDING'">
          <a-button type="primary" :style="{ marginRight: '8px' }" @click="examineFun('PASSED')"> 审核通过 </a-button>
          <a-button :style="{ marginRight: '8px' }" @click="examineFun('REJECTED')"> 不通过 </a-button>
        </div>
        <div class="taskFormBtn" v-if="selType === 'OWN' && originFormInfoData.businessTaskStatus === 'PENDING'">
          <a-button type="danger" :style="{ marginRight: '8px' }" @click="cancelFun"> 撤销 </a-button>
        </div>
      </div>
    </a-drawer>
    <a-modal
      title="撤销审批"
      :visible="visibleCancel"
      @ok="saveCancel"
      @cancel="cancelCancel"
      okText="确定撤销"
      cancelText="关闭"
      :okButtonProps="{
        props: {
          type: 'danger',
        },
      }"
    >
      <div>
        <div class="cancelTxt">您确定<span style="color: red"> 撤销 </span>该课时审批吗？</div>
        <div>确定撤销后，审核人员将不再会对该记录进行审批</div>
      </div>
    </a-modal>

    <a-drawer title="操作" placement="right" width="700" :visible="operatevisible" @close="clearnCurrentView">
      <TestOperate
        v-if="operatevisible"
        :classType="originFormInfoData.classType"
        :taskType="originFormInfoData.type"
        :taskTypeName="originFormInfoData.processVariables.question"
        :taskStatus="taskStatus"
        @onSubmit="onGetformData"
        @clearnCurrentView="clearnCurrentView"
        :source="subDetailType"
      >
      </TestOperate>
    </a-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import { MyProcessingRecords, TestOperate } from '@wk/tch-sc';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import defaultImg from '@/assets/service_pic_head.png';
import { getDdlLabel } from '@/utils/util';
import { Image } from 'element-ui';
import {
  feedBackDetail,
  getHeadmasterList,
  initOptions,
  submitClassHourAdjust,
  queryCourseSchedule,
  cancelApprove,
  queryStudentOrderStatus,
  completeTaskDetail,
  rejectTaskDetail,
  queryChangeReason,
} from '@/api/headTeacher';

export default {
  name: 'classApproval',
  components: { InfoTitle, MyProcessingRecords, TestOperate, ElImage: Image },
  data() {
    return {
      taskId: '',
      getDdlLabel,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      transactionReason: undefined,
      reasonArr: {},
      taskStatus: 'complate',
      originFormInfoData: {},
      operatevisible: false,
      options: {},
      subDetailType: 'classAdmin',
      assigneesList: [],
      visibleCancel: false,
      searchName: '',
      userInfo: {},
      courseScheduleObj: {},
      tableSorter: {},
      remarkTC: '',
      examineTitle: '',
      visibleResult: false,
      actualData: {},
      visibleExamine: false,
      defaultImg,
      formInfoData: {
        detail: {
          cuTransferMap: {},
        },
        student: {},
      },
      selType: 'PENDING',
      headmasterList: [],
      submitterId: undefined,
      transactionCategory: undefined,
      transactionCategoryArr: [
        { label: '课时转移', value: 'TRANSFER' },
        { label: '课时返还', value: 'RECOVER' },
        { label: '课时赠送', value: 'COMPENSATE' },
      ],
      approveStatus: undefined,
      approveStatusArr: [
        { label: '待审核', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
        { label: '已撤消', value: 'CANCEL' },
      ],
      feedBackTypeEnums: {
        EXPECT: '学习期望',
        FEED_BACK: '教学反馈',
        OPERATION: '排课操作',
        FAULT: '系统故障',
      },
      visible: false,
      pageNum: 1,
      selTabData: [
        { label: '我提交', value: 'OWN' },
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
      ],

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '申请人',
          dataIndex: 'processVariables.startUserName',
          key: 'startUserName',
        },

        {
          title: '变更学生',
          dataIndex: 'studentName',
          key: 'studentName',
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  const routeUrl = this.$router.resolve({
                    path: '/studentDetail',
                    query: {
                      uuid: record.processVariables.student.studentId,
                    },
                  });
                  window.open(routeUrl.href, '_blank');
                },
              },
            };
          },
        },
        {
          title: '科目',
          dataIndex: 'processVariables.student.subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
          width: 80,
        },
        {
          title: '课时变更类型',
          dataIndex: 'processVariables.transactionCategory',
          key: 'transactionCategory',
          scopedSlots: { customRender: 'transactionCategory' },
          width: 140,
        },
        {
          title: '变更原因',
          customRender: (text, record) => {
            return this.getFeedbackReason(record.processVariables.detail);
          },
        },
        {
          title: '申请变更日期',
          dataIndex: 'processVariables.createTime',
          key: 'createTime',
          sorter: true,
        },
        {
          title: '审批状态',
          dataIndex: 'finish',
          key: 'finish',
          scopedSlots: { customRender: 'finish' },
        },

        {
          title: '审核人',
          dataIndex: 'processVariables.approver',
          key: 'approver',
          // scopedSlots: { customRender: 'approverName' },
        },

        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      loading: false,
    };
  },
  methods: {
    moment,
    getFeedbackReason(detail) {
      // 课节反馈
      if (detail.feedbackCategory) {
        return `${this.feedBackTypeEnums[detail.feedbackCategory]}（${detail.feedbackReason.join('、')}）`;
      }
      return detail.transactionReason || '-';
    },

    onGetformData(data) {
      let params = {};
      switch (data.taskType) {
        case 'complate':
          params = {
            message: data.formData.message,
            userId: this.userInfo.userID,
            taskId: this.originFormInfoData.id,
            processInstanceId: this.originFormInfoData.processInstanceId,
          };
          completeTaskDetail(params, this.originFormInfoData.id, this.originFormInfoData.processInstanceId).then(() => {
            this.onCloseCourse();
          });
          break;
        case 'refuse':
          params = {
            taskId: this.originFormInfoData.id,
            userId: this.userInfo.userID,
            reason: data.formData.reason,
            processInstanceId: this.originFormInfoData.processInstanceId,
          };
          rejectTaskDetail(params).then(() => {
            this.onCloseCourse();
          });
          break;
        default:
          break;
      }
    },
    changeCategory() {
      this.transactionReason = undefined;
      this.initSearchData('search');
    },
    clearnCurrentView() {
      this.operatevisible = false;
    },

    onCloseCourse() {
      this.operatevisible = false;
      this.visible = false;
      this.initSearchData('search');
    },

    // 获取抽屉里反馈详情
    feedBackDetail(datas) {
      const params = {
        processInstanceId: datas.processInstanceId,
        taskId: datas.id,
      };
      feedBackDetail(params).then((res) => {
        this.assigneesList = this.initRole(res.data.content.taskLogs);
      });
    },
    initRole(datas) {
      return datas.map((item) => {
        let accounts = null;
        let taskFromAccount = null;
        if (item.accounts) {
          accounts = item.accounts.map((i) => {
            const rolesC = [];
            if (i && i.roles) {
              i.roles.forEach((k) => {
                rolesC.push(this.initOptionsType(k, this.options.userRole));
              });
            }
            return {
              ...i,
              rolesC: rolesC.join('/'),
            };
          });
        }
        if (item.taskFromAccount) {
          taskFromAccount = item.taskFromAccount;
          const taskFromAccountRoles = [];
          if (taskFromAccount.roles) {
            taskFromAccount.roles.forEach((k) => {
              taskFromAccountRoles.push(this.initOptionsType(k, this.options.userRole));
            });
          }

          taskFromAccount.rolesC = taskFromAccountRoles.join('/');
        }
        return {
          ...item,
          taskFromAccount,
          accounts,
        };
      });
    },
    // 枚举类型
    initOptions() {
      initOptions().then((res) => {
        this.options = res.data.content;
      });
    },

    cancelFun() {
      this.visibleCancel = true;
    },
    saveCancel() {
      const params = {
        taskId: this.originFormInfoData.id,
        processInstanceId: this.originFormInfoData.processInstanceId,
      };

      cancelApprove(params).then(() => {
        this.visibleCancel = false;
        this.originFormInfoData.businessTaskStatus = 'CANCEL';
        // this.visible = false;

        // this.initSearchData();
      });
    },
    cancelCancel() {
      this.visibleCancel = false;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    playback() {
      window.open(this.courseScheduleObj.liveUrl, '_blank');
    },

    // 查询课节
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };

      queryCourseSchedule(params).then((res) => {
        this.courseScheduleObj = res.data.content;
      });
    },
    cancelResult() {
      this.visibleResult = false;
      this.cancelExamine();
      this.visible = false;
      this.initSearchData();
    },
    examineFun(val) {
      this.approveStatusTC = val;
      this.operatevisible = true;
      if (val === 'PASSED') {
        this.examineTitle = '审核通过';
        this.taskStatus = 'complate';
      } else {
        this.examineTitle = '审核不通过';
        this.taskStatus = 'refuse';
      }
    },
    cancelExamine() {
      this.visibleExamine = false;
      this.remarkTC = '';
    },
    saveExamine() {
      const params = {
        reviewId: this.formInfoData.uuid,
        approveStatus: this.approveStatusTC,
        remark: this.remarkTC,
      };
      submitClassHourAdjust(params).then((res) => {
        if (res.data.content !== null) {
          this.actualData = res.data.content;
          this.visibleResult = true;
        } else {
          this.$message.success('提交成功');
          this.cancelExamine();
          this.visible = false;
          this.initSearchData();
        }
      });
    },
    hrefStudent() {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: this.formInfoData.studentId },
      });
      window.open(routeUrl.href, '_blank');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onClose() {
      this.visible = false;
    },
    lookDetail(record) {
      record.processVariables.detail.imageUrl = typeof record.processVariables.detail.imageUrl==='string'
      ?[record.processVariables.detail.imageUrl]
      :record.processVariables.detail.imageUrl

      this.formInfoData = record.processVariables;


      console.log('record.processVariables',record.processVariables);

      this.originFormInfoData = record;
      this.feedBackDetail(record);

      if (['RECOVER'].includes(this.formInfoData.transactionCategory)) {
        this.queryCourseSchedule(this.formInfoData.detail.courseScheduleId);
      }

      this.visible = true;
    },
    onSearchReport() {},

    searchHeadmaster(name) {
      const params = {
        roles: [
          'ADMIN',
          'SUPER_ADMIN',
          'X_WUKONG',
          'HEAD_TEACHER_LEADER',
          'HEAD_TEACHER_SUPER',
          'CLASS_ADMIN',
          'TEACHING_STAFF',
          'TEACHING_STAFF_ADMIN',
        ],
        name,
      };
      getHeadmasterList(params).then((res) => {
        console.log(res);
        this.headmasterList = res.data.content;
        console.log(this.headmasterList);
      });
    },
    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;

      this.pageNum = data.current;
      this.initPagination.current = data.current;

      this.initSearchData();
    },

    resetCondition() {
      this.submitterId = undefined;
      this.approveStatus = undefined;
      this.transactionCategory = undefined;
      this.searchName = undefined;
      this.transactionReason = undefined;
      this.pageNum = 1;
      this.pageSize = 10;
      this.initSearchData('search');
    },
    queryChangeReason() {
      const params = {
        nodeTag: 'STUDENT_CU_TRANSFER',
      };
      queryChangeReason(params).then((res) => {
        this.reasonArr = res.data.content;
      });
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        type: this.selType,
        studentCondition: this.searchName,
        startUserId: this.submitterId,
        approveStatus: this.approveStatus,
        transactionCategory: this.transactionCategory,
        transactionReason: this.transactionReason,
        currentPage: this.pageNum,
        pageSize: this.pageSize,
        taskId: this.taskId,
        categorySet: ['【中文】课时审批', '【数学】课时审批', '【英文】课时审批'],
      };
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
      }

      queryStudentOrderStatus(params).then((res) => {
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.pageSize = res.data.pageSize;
        this.loading = false;
      });
    },
  },
  created() {
    this.initOptions();
    this.userInfo = storage.get('userInfo');
    // 如果是飞书通知过来的，筛选出待审批的数据
    this.taskId = this.$route.query.taskId || '';
    if (this.taskId) {
      this.selType = 'PENDING';
    } else if (this.userInfo.roleName === 'CLASS_ADMIN') {
      this.selType = 'OWN';
    }

    this.queryChangeReason();
  },
  mounted() {
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 120px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 80%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.operation {
  color: #3372fe;
  cursor: pointer;
}

.table-wrap {
  padding: 20px;
  background: #fff;
  min-height: calc(100vh - 200px);
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .cancel-status {
      color: #454444;
      background-color: rgba(118, 114, 114, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
      // background-color: #009cff;
    }
  }
}
.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
}
/deep/.image-content {
  flex: 1;
  display: flex;
  gap: 8px;
  .el-image {
    width: 72px;
    height: 72px;
  }
}

.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.trans-itemLabel {
}
.line {
  border-bottom: 1px solid #e8e8e8;
}
.cancelTxt {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
</style>
