<template>
  <div>
    <div class="task-nav">
      <div style="flex: 1">
        <a-row :gutter="24">
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" class="ant-advanced-search-form">
            <a-col :span="6">
              <a-form-model-item label="学生">
                <a-input
                  placeholder="姓名或学号"
                  v-model="searchName"
                  allow-clear
                  @pressEnter="initSearchData('search')"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="申请人">
                <a-select
                  show-search
                  allowClear
                  @search="searchHeadmaster"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  v-model="submitterId"
                >
                  <a-select-option v-for="item in headmasterList" :key="item.uuid" :value="item.uuid">{{
                    item.fullName
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="审批状态">
                <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="approveStatus">
                  <a-select-option :value="item.value" v-for="(item, index) in approveStatusArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
      </div>
      <div class="button-box">
        <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
        <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
      </div>
    </div>
    <div class="table-wrap">
      <div class="sel-navwrap">
        <div class="sel-left">
          <a-select
            v-model="selType"
            class="sel-task"
            :default-value="selTabData[0].value"
            style="width: 110px; font-weight: bold"
            @change="initSearchData('search')"
          >
            <a-select-option v-for="item in selTabData" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="studentName" slot-scope="studentName, record">
          <span>{{ record.studentName }} ({{ record.studentCode }})</span>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ getDdlLabel(subjectArr, subject) }}</span>
        </template>
        <template slot="approverName" slot-scope="approverName, record">
          <span v-if="record.approverFirstName"
            >{{ `${record.approverFirstName ? record.approverFirstName : ''}` }}
            <span v-if="approverName">{{ `,${approverName}` }}</span>
          </span>
          <span v-else-if="approverName">{{ approverName }}</span>
          <span v-else>-</span>
        </template>
        <template slot="freezeToDate" slot-scope="freezeToDate, record">
          <span>{{ `${record.data.freezeFromDate} - ${freezeToDate}` }}</span>
        </template>
        <template slot="approveStatus" slot-scope="approveStatus">
          <span :style="{ color: approveStatus === 'REJECTED' ? 'red' : '' }">{{
            initOptionsType(approveStatus, approveStatusArr)
          }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">查看详情</span>
        </template>
      </a-table>
    </div>

    <a-drawer
      class="test-main"
      title="审批详情"
      :body-style="{ paddingBottom: '80px' }"
      placement="right"
      width="700"
      :visible="visible"
      @close="onClose"
    >
      <div class="task-info" v-if="formInfoData">
        <div class="task-form">
          <p class="form-name" style="margin-bottom: 12px">
            {{ formInfoData.data && formInfoData.data.toStatus }}
            <span class="pending-status" v-if="formInfoData.approveStatus === 'PENDING'">待审核</span>
            <span class="success-status" v-if="formInfoData.approveStatus === 'PASSED'">已通过</span>
            <span class="refuse-status" v-if="formInfoData.approveStatus === 'REJECTED'">已拒绝</span>
          </p>
          <div class="form-teacher">
            <img :src="defaultImg" alt="" />
            <span>申请人：{{ formInfoData.submitterName }}</span>
            |
            <span>申请时间：{{ formInfoData.submitTime }}</span>
          </div>
        </div>
        <div class="line-wrap">
          <InfoTitle title="关联学生" />
          <div class="student-wrap">
            <div class="student-item">
              <img :src="defaultImg" alt="" class="handler-img" />
              <div>
                <p class="student-name cursor" @click="hrefStudent">
                  {{ formInfoData.studentName }} ({{ formInfoData.studentCode }})>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="apply-detail">
          <InfoTitle title="申请详情" />
          <div class="apply-detailContent">
            <div class="apply-item">
              <span class="apply-label">变更学员：</span
              ><span class="apply-content">{{ `${formInfoData.studentName}(${formInfoData.studentCode})` }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">变更状态科目：</span
              ><span class="apply-content">{{ getDdlLabel(subjectArr, formInfoData.subject) }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">当前状态(变更前)：</span
              ><span class="apply-content">{{ formInfoData.data && formInfoData.data.fromStatus }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">变更状态为：</span
              ><span class="apply-content">{{ formInfoData.data && formInfoData.data.toStatus }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">变更原因I级：</span
              ><span class="apply-content">{{ (formInfoData.data && formInfoData.data.changeReasonOne) || '-' }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">变更原因II级：</span
              ><span class="apply-content">{{ (formInfoData.data && formInfoData.data.changeReasonTwo) || '-' }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">延期/冻结时间：</span
              ><span class="apply-content">{{
                formInfoData.data ? `${formInfoData.data.freezeFromDate} - ${formInfoData.data.freezeToDate}` : ''
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">上传图片：</span
              ><span class="apply-content"
                ><img
                  v-if="formInfoData.imageUrl"
                  style="width: 40px; height: 40px"
                  :src="formInfoData.imageUrl"
                  preview="1"
                  alt=""
              /></span>
            </div>
            <div class="apply-item">
              <span class="apply-label">备注：</span><span class="apply-content">{{ formInfoData.remark }}</span>
            </div>
          </div>
        </div>
        <div class="apply-detail">
          <InfoTitle title="审批结果" />
          <div class="apply-detailContent">
            <a-timeline
              style="margin-top: 16px"
              v-if="formInfoData.reviewRecords && formInfoData.reviewRecords.length > 0"
            >
              <a-timeline-item v-for="(item, index) in formInfoData.reviewRecords" :key="index">
                <div class="apply-item">
                  <span class="apply-label">审批人：</span><span class="apply-content">{{ item.approverName }}</span>
                </div>
                <div class="apply-item">
                  <span class="apply-label">审批结果：</span
                  ><span class="apply-content">{{ initOptionsType(item.approveStatus, approveStatusArr) }}</span>
                </div>
                <div class="apply-item">
                  <span class="apply-label">审批备注：</span><span class="apply-content">{{ item.remark }}</span>
                </div>
              </a-timeline-item>
            </a-timeline>
            <a-timeline style="margin-top: 16px" v-else>
              <a-timeline-item>
                <div class="apply-item">
                  <span class="apply-label">审批人：</span><span class="apply-content"></span>
                </div>
                <div class="apply-item">
                  <span class="apply-label">审批结果：</span><span class="apply-content"></span>
                </div>
                <div class="apply-item">
                  <span class="apply-label">审批备注：</span><span class="apply-content"></span>
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>
        <div class="taskFormBtn" v-if="selType === 'PENDING'">
          <a-button type="primary" :style="{ marginRight: '8px' }" @click="examineFun('PASSED')"> 审核通过 </a-button>
          <a-button :style="{ marginRight: '8px' }" @click="examineFun('REJECTED')"> 不通过 </a-button>
        </div>
      </div>
    </a-drawer>
    <a-modal
      :title="examineTitle"
      :visible="visibleExamine"
      @ok="saveExamine"
      @cancel="cancelExamine"
      okText="提交"
      cancelText="取消"
    >
      <div>
        <div class="examine-item">
          <div class="examine-label">审核结果：</div>
          <div class="examine-content" :style="{ color: examineTitle === '审核通过' ? '#43d186' : 'red' }">
            {{ examineTitle }}
          </div>
        </div>
        <div class="examine-item">
          <div class="examine-label">备注：</div>
          <div class="examine-content">
            <a-textarea placeholder="请填写" v-model="remarkTC" />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import storage from 'store';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import defaultImg from '@/assets/service_pic_head.png';

import {
  getHeadmasterList,
  queryStudentStatusApproval,
  submitStatusApproval,
  queryCourseSchedule,
} from '@/api/headTeacher';
import { getDdlLabel } from '@/utils/util';

export default {
  name: 'classApproval',
  components: { InfoTitle },
  data() {
    return {
      getDdlLabel,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      searchName: '',
      userInfo: {},
      courseScheduleObj: {},
      tableSorter: {},
      remarkTC: '',
      examineTitle: '',
      visibleResult: false,
      actualData: {},
      visibleExamine: false,
      defaultImg,
      formInfoData: {},
      selType: 'PENDING',
      headmasterList: [],
      submitterId: undefined,
      transactionCategory: undefined,
      transactionCategoryArr: [
        { label: '冻结课程', value: '冻结课程' },
        { label: '延期开课', value: '延期开课' },
      ],
      approveStatus: undefined,
      approveStatusArr: [
        { label: '待审核', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
      ],
      visible: false,
      pageNum: 1,
      selTabData: [
        { label: '我提交', value: 'OWN' },
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
      ],

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '申请人',
          dataIndex: 'submitterName',
          key: 'submitterName',
        },

        {
          title: '变更学生',
          dataIndex: 'studentName',
          key: 'studentName',
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  const routeUrl = this.$router.resolve({
                    path: '/studentDetail',
                    query: {
                      uuid: record.studentId,
                    },
                  });
                  window.open(routeUrl.href, '_blank');
                },
              },
            };
          },
        },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '期望变更状态',
          dataIndex: 'data.toStatus',
          key: 'toStatus',
        },
        {
          title: '延期/冻结时间',
          dataIndex: 'data.freezeToDate',
          key: 'freezeToDate',
          scopedSlots: { customRender: 'freezeToDate' },
        },
        {
          title: '申请日期',
          dataIndex: 'submitTime',
          key: 'submitTime',
          sorter: true,
        },
        {
          title: '审批状态',
          dataIndex: 'approveStatus',
          key: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' },
        },
        //  {
        //   title: '审核人',
        //   dataIndex: 'approverName',
        //   key: 'approverName',
        //   scopedSlots: { customRender: 'approverName' },

        //  },

        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      loading: false,
    };
  },
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    playback() {
      window.open(this.courseScheduleObj.liveUrl, '_blank');
    },

    // 查询课节
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };

      queryCourseSchedule(params).then((res) => {
        this.courseScheduleObj = res.data.content;
      });
    },
    cancelResult() {
      this.visibleResult = false;
      this.cancelExamine();
      this.visible = false;
      this.initSearchData();
    },
    examineFun(val) {
      this.approveStatusTC = val;
      if (val === 'PASSED') {
        this.examineTitle = '审核通过1';
      } else {
        this.examineTitle = '审核不通过';
      }
      this.visibleExamine = true;
    },
    cancelExamine() {
      this.visibleExamine = false;
      this.remarkTC = '';
    },
    saveExamine() {
      const params = {
        reviewId: this.formInfoData.uuid,
        approveStatus: this.approveStatusTC,
        remark: this.remarkTC,
      };
      submitStatusApproval(params).then(() => {
        this.$message.success('提交成功');
        this.cancelExamine();
        this.visible = false;
        this.initSearchData();
      });
    },
    hrefStudent() {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: this.formInfoData.studentId },
      });
      window.open(routeUrl.href, '_blank');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      // eslint-disable-next-line no-unused-expressions
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onClose() {
      this.visible = false;
    },
    lookDetail(record) {
      this.formInfoData = record;

      this.visible = true;
    },
    onSearchReport() {},

    searchHeadmaster(name) {
      const params = {
        roles: [
          'ADMIN',
          'SUPER_ADMIN',
          'X_WUKONG',
          'HEAD_TEACHER_LEADER',
          'HEAD_TEACHER_SUPER',
          'CLASS_ADMIN',
          'TEACHING_STAFF',
          'TEACHING_STAFF_ADMIN',
        ],
        name,
      };
      getHeadmasterList(params).then((res) => {
        console.log(res);
        this.headmasterList = res.data.content;
        console.log(this.headmasterList);
      });
    },
    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;

      this.pageNum = data.current;
      this.initPagination.current = data.current;

      this.initSearchData();
    },

    resetCondition() {
      this.submitterId = undefined;
      this.approveStatus = undefined;
      this.transactionCategory = undefined;
      this.searchName = undefined;
      this.pageNum = 1;
      this.pageSize = 10;
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        statusType: this.selType,
        name: this.searchName,
        submitterId: this.submitterId,
        approveStatus: this.approveStatus,
        type: 'STUDENT_STATUS',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
      }

      queryStudentStatusApproval(params).then((res) => {
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.pageSize = res.data.pageSize;
        this.loading = false;
      });
    },
  },
  created() {
    this.userInfo = storage.get('userInfo');
    if (this.userInfo.roleName === 'CLASS_ADMIN') {
      this.selType = 'OWN';
    }
  },
  mounted() {
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
.operation {
  color: #3372fe;
  cursor: pointer;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
  min-height: calc(100vh - 200px);
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
      // background-color: #009cff;
    }
  }
}
.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.trans-itemLabel {
}
</style>
