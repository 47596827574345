<template>
  <div class="approval-wrap">
    <a-tabs type="card" v-model="currentType" @change="changePage">
      <a-tab-pane key="STUDENT_CU_REVIEW" tab="课时审批">
        <classApproval></classApproval>
      </a-tab-pane>
      <a-tab-pane key="STUDENT_STATUS_REIVEW" tab="学员状态审批">
        <studentStatusApproval></studentStatusApproval>
      </a-tab-pane>
     <a-tab-pane key="ORDER_SUBMIT_APPROVE" tab="订单审批">
        <orderApproval></orderApproval>
      </a-tab-pane>
       <a-tab-pane key="CU_APPLY_DELAY_REVIEW" tab="有效期审批">
        <classHourApproval></classHourApproval>
      </a-tab-pane>
    </a-tabs>
     <div class="class-tip position1" v-if="stuCuCount>0">{{stuCuCount}}</div>
     <div class="class-tip position2" v-if="stuStatusCount>0">{{stuStatusCount}}</div>
    <div class="class-tip position3" v-if="orderTaskCount>0">{{orderTaskCount}}</div>
   <div class="class-tip position4" v-if="cuExpireTaskCount>0">{{cuExpireTaskCount}}</div>

  </div>
</template>

<script>
import classApproval from './classApproval.vue';
import studentStatusApproval from './studentStatusApproval.vue';
import orderApproval from './orderApproval.vue'
import classHourApproval from './classHourApproval.vue'
import {
 queryStatisticsNum
} from '@/api/headTeacher';
export default {
  name: 'courseDurationApprovalNew',
  components: {
    classApproval,
    studentStatusApproval,
    orderApproval,
    classHourApproval
  },
  data() {
    return {
    currentType:'',
      orderTaskCount:0,
      stuStatusCount:0,
      stuCuCount:0,
      cuExpireTaskCount:0,
    };
  },
  methods: {
    changePage() {},
  },
  created(){
  //飞书通知点击打开对应的审批
  let type=this.$route.query.taskType;
 
  this.currentType=type?type:'STUDENT_CU_REVIEW'
  

    queryStatisticsNum().then(res=>{
      let data=res.data.content;
      this.orderTaskCount=data.orderTaskCount;
      this.stuCuCount=data.stuCuCount;
      this.stuStatusCount=data.stuStatusCount;
      this.cuExpireTaskCount=data.cuExpireTaskCount
    })
  }
};
</script>

<style scoped lang="less">
.approval-wrap {
  width: 100%;
  min-height: calc(100vh - 64px);
  position: relative;
}
/deep/ .ant-tabs-bar {
  padding: 12px 16px 0;
  background-color: #fff;
  margin: 0;
}
.class-tip{
    padding: 0px 2px;
   min-width:20px;
   text-align: center;
    background-color: red;
    color: #fff;
    position: absolute;
    top: 3px;
    border-radius: 100%;
}
.position1{
  
   left: 85px;
}
.position2{
  
   left: 201px;
}
.position3{
  
   left: 295px;
}
.position4{
  
   left: 407px;
}
.ant-tabs.ant-tabs-card{
  position: relative;
}
</style>
